import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <p style={{textAlign: 'center'}}><strong><span style={{color: '#ff0000', fontSize: 'large'}}>How to Spend Each Day with God</span></strong></p>
        <p style={{textAlign: 'center'}}>Richard Baxter<br />&nbsp;</p>
        <blockquote style={{textAlign: 'justify'}}>
          <blockquote>
            <p>A&nbsp;<em>holy life</em>&nbsp;is inclined to be made easier, when we know the usual sequence and method of our duties — with everything falling into its proper place. Therefore, I shall give some&nbsp;<em>brief directions for spending each day in a holy manner.</em></p>
            <p><strong><span style={{color: '#008000'}}>Sleep</span><br /></strong>Measure the time of your sleep appropriately so that you do not waste your precious morning hours sluggishly in your bed. Let the time of your sleep be matched to your health and labor, and not to slothful pleasure.</p>
            <p><strong><span style={{color: '#008000'}}>First Thoughts</span><br /></strong>Let God have your first awaking thoughts; lift up your hearts to Him reverently and thankfully for the rest enjoyed the night before, and cast yourself upon Him for the day which follows.</p>
            <p>Familiarize yourself so consistently to this that your conscience may check you when common thoughts shall first intrude. Think of the mercy of a night's rest and of how many that have spent that night in Hell; how many in prison; how many in cold, hard lodgings; how many suffering from agonizing pains and sickness, weary of their beds and of their lives.</p>
            <p>Think of how many souls were that night called from their bodies terrifyingly to appear before God and think how quickly days and nights are rolling on! How speedily your last night and day will come! Observe that which is lacking in the preparedness of your soul for such a time and seek it without delay.</p>
            <p><strong><span style={{color: '#008000'}}>Prayer</span><br /></strong>Let prayer by yourself alone (or with your partner) take place before the collective prayer of the family. If possible let it be first, before any work of the day.</p>
            <p><strong><span style={{color: '#008000'}}>Family Worship</span><br /></strong>Let family worship be performed consistently and at a time when it is most likely for the family to be free of interruptions.</p>
            <p><strong><span style={{color: '#008000'}}>Ultimate Purpose</span><br /></strong>Remember your ultimate purpose, and when you set yourself to your day's work or approach any activity in the world, let<em>&nbsp;HOLINESS TO THE LORD</em>&nbsp;be written upon your hearts in all that you do. Do no activity which you cannot entitle God to, and truly say that he set you about it — and do nothing in the world for any other ultimate purpose than to please, glorify and enjoy Him. "Whatever you do, do all to the glory of God." 1 Corinthians 10:31.</p>
            <p><strong><span style={{color: '#008000'}}>Diligence in Your Calling</span><br /></strong>Follow the tasks of your calling&nbsp;<em>carefully&nbsp;</em>and&nbsp;<em>diligently</em>. Thus:</p>
            <p>(a) You will show that you are not sluggish and servants to your flesh (as those that cannot deny it ease), and you will further the putting to death of all the fleshly lusts and desires that are fed by ease and idleness.</p>
            <p>(b) You will keep out idle thoughts from your mind, that swarm in the minds of idle persons.</p>
            <p>(c) You will not lose&nbsp;<em>precious time</em>, something that idle persons are daily guilty of.</p>
            <p>(d) You will be in a way of obedience to God when the slothful are in constant sins of omission.</p>
            <p>(e) You may have more time to spend in holy duties if you follow your occupation diligently. Idle people have no time for praying and reading, because they lose time by loitering at their work.</p>
            <p>(f) You may expect God's blessing and comfortable provision for both yourself and your families.</p>
            <p>(g) It may also encourage the health of your body which will increase its competence for the service of your soul.</p>
            <p><strong><span style={{color: '#008000'}}>Temptations and Things That Corrupt</span><br /></strong>Be thoroughly acquainted with your besetting temptations and the things that may corrupt you — and watch against them all day long. You should watch especially the most dangerous of the things that corrupt, and those temptations that either your company or business will unavoidably lay before you.</p>
            <p>Watch against the master sins of . . .<br />unbelief,<br />hypocrisy,<br />selfishness,<br />pride,<br />flesh-pleasing and<br />the excessive love of earthly things.</p>
            <p>Take care against being drawn into earthly-mindedness and excessive cares, or covetous designs for rising in the world, under the pretense of diligence in your calling.</p>
            <p>If you are to trade or deal with others, be vigilant against selfishness and all that smacks of injustice or uncharitableness. In all your interactions with others, watch against the temptation of empty and idle talking. Watch also against those persons who would tempt you to anger. Maintain that modesty and cleanness of speech that the laws of purity require.</p>
            <p>If you converse with flatterers — be on your guard against swelling pride.</p>
            <p>If you converse with those that despise and injure you — strengthen yourself against impatient, revengeful pride.</p>
            <p>At first these things will be very difficult, while sin has any strength in you, but once you have grasped a continual awareness of the poisonous danger of any one of these sins, your heart will readily and easily avoid them.</p>
            <p><strong><span style={{color: '#008000'}}>Meditation</span><br /></strong>When alone in your occupations, improve the time in practical and beneficial meditations. Meditate upon . . .<br />the infinite goodness and perfections of God;<br />Christ and redemption;<br />Heaven and how unworthy you are of going there;<br />and how you deserve eternal misery in Hell.</p>
            <p><strong><span style={{color: '#008000'}}>The Only Motive</span><br /></strong>Whatever you are doing, in company or alone, do it all to the glory of God (1 Corinthians 10:31). Otherwise, it is unacceptable to God.</p>
            <p><strong><span style={{color: '#008000'}}>Redeeming The Time</span><br /></strong>Place a high value upon your&nbsp;<em>time</em>, be more careful of not losing it than you would of losing your&nbsp;<em>money</em>. Do not let . . .<br />worthless recreations,<br />worldly entertainment,<br />idle talk,<br />unprofitable company,<br />or sleep —<br />rob you of your&nbsp;<em>precious time</em>.</p>
            <p>Be more careful to escape that person, action or course of life which would rob you of your&nbsp;<em>time</em>&nbsp;— than you would be to escape thieves and robbers.</p>
            <p>Make sure that you are not merely never idle, but rather that you are using your time in the most profitable way that you can, and do not prefer a less profitable way before one of greater profit.</p>
            <p><strong><span style={{color: '#008000'}}>Eating and Drinking</span><br /></strong>Eat and drink with moderation and thankfulness for health, not for unprofitable pleasure. Never please your appetite in food or drink, when it is prone to be detrimental to your health.</p>
            <p>Remember the&nbsp;<em>sin of Sodom:&nbsp;</em>"Look, this was the iniquity of your sister Sodom: She and her daughter had pride, fullness of food and abundance of idleness" Ezekiel 16:49.</p>
            <p>The Apostle Paul wept when he mentioned those "whose end is destruction, whose god is their belly, and whose glory is in their shame — who set their minds on earthly things, being enemies to the cross of Christ" Philippians 3:18-19. O then do not live according to the flesh, lest you die! (Romans 8:13).</p>
            <p><strong><span style={{color: '#008000'}}>Prevailing Sins</span><br /></strong>If any temptation prevails against you, and you fall into any sins in addition to habitual failures, immediately lament it and confess it to God; repent quickly whatever the cost. It will certainly cost you more if you continue in sin and remain unrepentant.</p>
            <p>Do not make light of your habitual failures, but confess them and daily strive against them, taking care not to aggravate them by unrepentance and contempt.</p>
            <p><strong><span style={{color: '#008000'}}>Relationships</span><br /></strong>Remember every day, the special duties of various relationships: whether as husbands, wives, children, masters, servants, pastors, people, magistrates, subjects.</p>
            <p>Remember every relationship has its special duty and its advantage for the doing of some good. God requires your faithfulness in this matter, as well as in any other duty.</p>
            <p><strong><span style={{color: '#008000'}}>Closing the Day</span><br /></strong>Before returning to sleep, it is wise and necessary to review the actions and mercies of the day past, so that you may be&nbsp;<em>thankful&nbsp;</em>for all the special mercies, and&nbsp;<em>humbled&nbsp;</em>for all your sins.</p>
            <p>This is necessary in order that you might renew your repentance as well as your resolve for obedience, and in order that you may examine yourself to see whether your soul grew better or worse, whether sin goes down and grace goes up, and whether you are better prepared for suffering, death and eternity.</p>
            <p>May these directions be engraved upon your mind and be made the daily practice of your life.</p>
            <p>If sincerely adhered to, these will be conducive to the&nbsp;<em>holiness</em>,&nbsp;<em>fruitfulness&nbsp;</em>and&nbsp;<em>quietness&nbsp;</em>of your life, and bring you to a comfortable and peaceful death.</p>
          </blockquote>
        </blockquote>
    </div>
  );
}

export default App;
